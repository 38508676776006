import React from 'react'
import './GetStarted.css'
const GetStarted = () => {
  return (
    <section className='g-wrapper'>
        <div className='paddings innerWidth g-container'>
            <div className='flexColCenter inner-container'>
                <span className='primaryText'>Get Started with Homyz</span>
                <span className='secondaryText'>Subscribe and find super attractive price quotes from Indy
                <br/>Find your residence easily</span>
                <button className='button'><a href='mailto:teghsingh2@gmail.com'>Get Started</a></button>
            </div>
            
        </div>
    </section>
  )
}

export default GetStarted
